import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Post from '../blog/post'

const FeaturedBlog = ({ block }) => {
    const featuredPostsQuery = useStaticQuery(graphql`
        query {
            posts:allSanityPost(sort: {fields: _createdAt, order: DESC}, limit: 3) {
                edges {
                    node {
                        _createdAt
                        id
                        date(formatString: "Do MMMM YYYY")
                        title
                        slug {
                            current
                        }
                        image {
                            asset {
                                gatsbyImageData(aspectRatio: 1.5)
                            }
                            alt
                        }
                    }
                }
            }
        }
    `)
    const featuredPosts = featuredPostsQuery.posts.edges
    return (
        <div className='container max-w-screen-lg mx-auto my-12 px-8 xl:px-0'>
            <div className='max-w-max mx-auto'>
                <h3 className='text-5xl text-center text-text-dark mb-8'>{block.title}</h3>
                <hr className='mb-8 border-bg-2' />
            </div>
            {featuredPosts.length > 0 ? (
                <div className={`grid gap-5 sm:grid-cols-2 ${featuredPosts.length < 3 ? 'md:grid-cols-2' : 'md:grid-cols-3'}`}>
                    {featuredPosts.map(post => (
                        <Post key={post.node.id} post={post.node} />
                    ))}
                </div>
            ) : (
                <h2>No posts..</h2>
            )}
            <div className='text-center mt-8'>
                <button className='bg-accent-1 text-text-light px-5 py-2 my-5 hover:shadow-lg shadow-accent-1 uppercase'>
                    <Link className='text-text-light' to="/blog">
                        View all posts
                    </Link>
                </button>
            </div>
        </div>
    )
}

export default FeaturedBlog
