import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import PortableText from "react-portable-text"

const ImageWithText = ({ block }) => {
    const { title, _rawText, image, link, CTA, about } = block
    let buttonLink
    const linkFinder = () => {
        if (link) {
            switch (link._type) {
                case 'collection':
                    buttonLink = `/collections/${link.slug.current}`;
                    break;
                case 'post':
                    buttonLink = `/posts/${link.slug.current}`;
                    break;
                case 'page':
                    buttonLink = `/${link.slug.current}`;
                    break;
                case 'product':
                    buttonLink = `/products/${link.store.slug.current}`;
                    break;
                default:
                    buttonLink = `/`
            }
        }
    }
    linkFinder()

    const rightAlign = block.rightAlign

    const serializers = {
        blockImage(props) {
            const caption = props.caption
            const imageAssetId = props.image.asset.id
            const imageData = getGatsbyImageData(imageAssetId, { maxWidth: 1024 }, { projectId: "57wf6c5n", dataset: "production" })
            const fullWidth = props.fullWidth
            return (
                <div className={`mb-2 ${fullWidth ? 'w-full' : 'w-1/2'}`}>
                    <GatsbyImage image={imageData} alt={props.alt ? props.alt : ""} />
                    <p className='text-gray-700 italic'>{caption}</p>
                </div>
            )
        },
        blockProduct(props) {
            const product = props.productWithVariant.product.store
            const lowPrice = Number(product.priceRange.minVariantPrice)
            const highPrice = Number(product.priceRange.maxVariantPrice)
            return (
                <Link key={product.title} className='group flex border border-gray-600 w-max pr-4 my-8 items-center' to={`/products/${product.slug.current}`}>
                    <div className='overflow-hidden max-h-36 w-16'>
                        <img className='transform hover:scale-105 transition-transform duration-700' width="" src={product.previewImageUrl} alt={product.title} />
                    </div>
                    <div className='pl-2'>
                        <p className='text-xl group-hover:underline'>{product.title}</p>
                        {lowPrice !== highPrice ? (
                            <p>From £{lowPrice.toFixed(2)}</p>
                        ) : (
                            <p>£{lowPrice.toFixed(2)}</p>
                        )}
                    </div>
                </Link>
            )
        },
        ul: ({ children }) => <ul className="list-disc list-inside">{children}</ul>,
        link(props) {
            return (
                <a href={props.href} rel='noopener noreferrer' target="_blank">{props.children}</a>
            )
        },
        annotationLinkInternal(props) {
            let link
            const linkFinder = () => {
                switch (props.reference._type) {
                    case 'collection':
                        link = `/collections/${props.reference.slug.current}`;
                        break;
                    case 'post':
                        link = `/posts/${props.reference.slug.current}`;
                        break;
                    case 'page':
                        link = `/${props.reference.slug.current}`;
                        break;
                    case 'product':
                        link = `/products/${props.reference.store.slug.current}`;
                        break;
                    default:
                        link = `/`
                }
            }
            linkFinder()
            return (
                <Link to={link}>{props.children}</Link>
            )
        },
        annotationLinkExternal(props) {
            return (
                <a href={props.url} rel="noopener noreferrer" target={props.newWindow ? "_blank" : "_self"} >{props.children}</a>
            )
        },
        annotationLinkEmail(props) {
            return (
                <a href={`mailto:${props.email}`}>{props.children}</a>
            )
        }
    }

    return (
        <div className='text-center md:text-left px-8 max-w-screen-2xl mx-auto'>
            {about === true ? (
                <div className='mb-24'>
                    <div className={`lg:flex ${rightAlign === false ? 'flex-row' : 'flex-row-reverse'}`}>
                        <div className='lg:w-2/5 md:m-2'>
                            <GatsbyImage
                                className='h-full w-max-full rounded-md'
                                image={image.asset.gatsbyImageData}
                                alt={image.alt ? image.alt : ""}
                            />
                        </div>
                        <div className='lg:w-3/5 text-text-dark py-5 md:py-0 md:pl-5 md:grid'>
                            <div>
                                <h3 className='text-3xl text-text-dark py-5'>{title}</h3>

                                <PortableText className='mb-12 text-lg' content={_rawText} serializers={serializers} />
                                {buttonLink ? (
                                    <Link className='button offset text-accent-1 px-5 py-3 uppercase' to={buttonLink}>{CTA}</Link>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='mb-24 px-2 md:px-8'>
                    <div className={`md:flex ${rightAlign === true ? 'flex-row' : 'flex-row-reverse'}`}>
                        <div className='md:w-3/5 text-text-dark py-5 md:py-0 md:grid grid relative'>
                            <div>
                                <h3 className='text-5xl text-text-dark font-medium py-5'>{title}</h3>
                                <hr className='mb-8 border-bg-2' />
                                <PortableText className='text-lg' content={_rawText} serializers={serializers} />
                            </div>
                            <div className='mt-8'>
                                {buttonLink ? (
                                    <Link className='button offset text-accent-1 px-5 py-3 uppercase inline-block' to={buttonLink}>{CTA}</Link>
                                ) : null}
                            </div>
                        </div>
                        <div className='relative md:w-2/5 flex md:justify-center items-center md:mr-8'>
                            <GatsbyImage
                                className='object-contain my-5 w-full md:absolute'
                                image={image.asset.gatsbyImageData}
                                alt={image.alt ? image.alt : ""}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ImageWithText
