import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const Testimonial = ({ block }) => {
    const { body, author, image } = block
    return (
        <div className='bg-white p-5 rounded testimonial mb-12'>
            {image && <GatsbyImage className='mr-5 w-24 h-24 float-left' image={image.asset.gatsbyImageData} alt={image.alt ? image.alt : ""} />}
            <blockquote className='text-xl'>{body}</blockquote>
            <div className='pt-2'>
            <p className='text-lg'>{author}</p>
            </div>
        </div>
    )
}

export default Testimonial
