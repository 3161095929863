import React from 'react'
import PageContent from './pageContent'

const Faq = ({ block }) => {
    const { question, answer } = block
    console.log(answer)
    return (
        <div className='bg-white p-5 rounded testimonial mb-12'>
            <p><strong>{question}</strong></p>
            <PageContent block={answer}/>
        </div>
    )
}

export default Faq
