import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const FeaturedCollection = ({ block }) => {
    const { products, CTA, link } = block
    const linkFinder = (props) => {
        let link
        switch (props._type) {
            case 'collection':
                link = `/collections/${props.slug.current}`;
                break;
            case 'post':
                link = `/posts/${props.slug.current}`;
                break;
            case 'home':
                link = `/`;
                break;
            case 'product':
                link = `/products/${props.store.slug.current}`;
                break;
            default:
                link = `/${props.slug.current}`
        } return (
            link
        )
    }
    return (
        <div className='my-24 px-8 xl:px-0'>
            <div className='max-w-max mx-auto'>
                <h3 className='text-5xl text-center text-text-dark mb-8'>Featured Products</h3>
                <hr className='mb-8 border-bg-2' />
            </div>
            <div className={`max-w-screen-lg mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-5 ${products.length < 4 ? null : 'md:grid-cols-4'}`}>
                {products.map(product => {
                    const lowPrice = Number(product.store.priceRange.minVariantPrice)
                    const highPrice = Number(product.store.priceRange.maxVariantPrice)
                    const image = product.images[0] ? product.images[0] : null
                    return (
                        <Link key={product.store.title} className='group card' to={`/products/${product.store.slug.current}`}>
                            {product.images[0] ? (
                                <div className='overflow-hidden'>
                                    <GatsbyImage className='transform hover:scale-105 transition-transform duration-700' image={image.asset.gatsbyImageData} alt={product.store.title} />
                                </div>
                                ) : (
                                <div className='square overflow-hidden'>
                                    <img className='absolute w-full h-full top-0 transform hover:scale-105 transition-transform duration-700 object-cover' width="" src={product.store.previewImageUrl} alt={product.store.title} />
                                </div>
                                )}
                            <div className='p-5 text-center'>
                                <h4 className='text-3xl text-text-dark group-hover:underline'>{product.store.title}</h4>
                                {lowPrice !== highPrice ? (
                                    <p>From £{lowPrice.toFixed(2)}</p>
                                ) : (
                                    <p>£{lowPrice.toFixed(2)}</p>
                                )}
                            </div>
                        </Link>
                    )
                })}
            </div>
            {link ? (
                <div className='text-center mt-8'>
                    <button className='bg-accent-1 text-text-light px-5 py-2 my-5 hover:shadow-lg shadow-accent-1 uppercase'>
                        <Link className='text-text-light' to={linkFinder(link)}>
                            {CTA}
                        </Link>
                    </button>
                </div>
            ) : null}
        </div>
    )
}

export default FeaturedCollection