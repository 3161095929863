import React from 'react'

const Pricing = ({ block }) => {
    return (
        <div className='max-w-screen-xl'>
            <h2 className='my-5'>Pricing Table</h2>
            <table className='bg-white text-text-dark table-auto border-collapse border border-bg-2 w-100 shadow-md rounded text-xl font-normal'>
                <tbody>
                    {block.rows.map((row, i) => (
                        <tr key={i} className='border border-bg-2'>
                            {row.cells.map((cell, i) => (
                                <td key={i} className='border border-bg-2 px-5 sm:px-8 py-2 sm:py-4'>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Pricing
