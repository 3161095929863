import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import ContentBlocks from '../components/homepage/contentBlocks';
import Layout from '../components/layout/layout'
import Seo from '../components/utils/seo'

const Contact = () => {
    const contentQuery = useStaticQuery(graphql`
    query {
      sanityContact {
            ...ContactContentBlocksFragment
            seo {
                title
                description
                image {
                    asset {
                        url
                    }
                }
            }
            }
      }
  `);
    const title = contentQuery.sanityContact.seo?.title
    const description = contentQuery.sanityContact.seo?.description
    const image = contentQuery.sanityContact.seo?.image?.asset.url
    return (
        <Layout>
            <Seo title={title} description={description} image={image} />
            <div className='mt-8 px-4 max-w-screen-lg mx-auto'>
                <h1 className='font-3xl text-center my-5'>Contact</h1>
                <ContentBlocks contentBlocks={contentQuery.sanityContact.contentBlocks} _rawContentBlocks={contentQuery.sanityContact._rawContentBlocks} />
                <div>
                    <form className='smb-8' action="/success" netlify-honeypot="bot-field" data-netlify="true" name="contact" method='post'>
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact" />
                        <label htmlFor='contactFormName'>
                            Name*
                            <input className='block w-full p-3 mb-5' name='name' type='text' placeholder='Name' id='contactFormName' required />
                        </label>
                        <label htmlFor='contactFormEmail'>
                            Email*
                            <input className='block w-full p-3 mb-5' name='email' type='email' placeholder='Email' id='contactFormEmail' required />
                        </label>
                        <label htmlFor='contactFormPhone'>
                            Phone Number
                            <input className='block w-full p-3 mb-5' name='phone' type='text' placeholder='Phone Number' id="contactFormPhone" />
                        </label>
                        <label htmlFor='contactFormBody'>
                            Message*
                            <textarea className='block w-full p-3 mb-5 border-1' name='message' placeholder='Message' id="contactFormBody" required />
                        </label>
                        <div>
                            <button type='submit' className='bg-accent-1 text-text-light px-5 py-2 my-5 hover:shadow-lg shadow-accent-1'>
                                Send
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default Contact
