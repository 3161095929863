import React from 'react'

const File = ({ block }) => {
    const file = block
    return (
        <div className='mt-2 text-xl'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <a href={file.asset.url} className='hover:underline' target="_blank" rel='noopener noreferrer'>{file.title}</a>
        </div>
    )
}

export default File