import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

const CollectionCard = ({ title, handle, featuredImage }) => {
    const image = getImage(featuredImage)
    return (
        <div className='mb-12'>
            <Link to={handle}>
                <GatsbyImage className='h-[50vh] w-full' image={image} alt={title} />
                <div className='bg-bg-3 flex items-center'>
                    <h3 className='p-5 text-text-light'>{title}</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-text-light" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </div>
            </Link>
        </div>
    )
}

export default CollectionCard
