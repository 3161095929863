import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import CollectionCard from './collectionCard'

const CollectionList = ({ block }) => {
    const { collections, title } = block
    const collectionCardQuery = useStaticQuery(graphql`
        query {
            sanitySettings {
                general {
                    image {
                        asset {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `)
    const placeholder = collectionCardQuery.sanitySettings.general.image.asset
    return (
        <div>
            {title && <h3 className='text-3xl mb-8'>{title}</h3>}
            <div className={`sm:grid ${collections.length < 3 ? 'grid-cols-2' : 'grid-cols-3'} gap-2`}>
                {collections.map(collection => {
                    const image = collection.image ? collection.image.asset : placeholder
                    return (
                        <CollectionCard key={collection.slug.current} title={collection.title} handle={`/collections/${collection.slug.current}`} featuredImage={image} />
                    )
                })
                }
            </div>
        </div>
    )
}

export default CollectionList
