import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const ThreeColumn = ({ block }) => {
    const { columns } = block
    return (
        <div className={`container max-w-screen-xl mx-auto my-12 px-8 xl:px-0 md:grid ${columns.length < 3 ? 'grid-cols-2' : 'grid-cols-3'} gap-8`}>
            {columns.map(column => (
                <div key={column._key} className={`text-center my-12 md:mx-12 ${column.image ? null : 'flex items-end'}`}>
                    {column.image && (
                        <div>
                            <GatsbyImage
                                className='h-full'
                                image={column.image.asset.gatsbyImageData}
                                alt={column.image.alt ? column.image.alt : ""}
                            />
                        </div>
                    )}
                    <div>
                        {column.title && <h3 className='text-3xl'>{column.title}</h3>}
                        {column.body && <p>{column.body}</p>}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ThreeColumn
