import React from 'react'
import Hero from './hero'
import FeaturedCollection from './featuredCollection'
import ImageWithText from './imageWithText'
import ThreeColumn from './threeColumn'
import CollectionList from './collectionList'
import PageContent from './pageContent'
import FeaturedBlog from './featuredBlog'
import Gallery from './gallery'
import Pricing from './pricing'
import File from './file'
import Testimonial from './testimonial'
import Faq from './faq'

const ContentBlocks = (props) => {
    const { type, contentBlocks, _rawContentBlocks } = props
    const Components = {
        hero: Hero,
        featuredProducts: FeaturedCollection,
        imageWithText: ImageWithText,
        threeColumn: ThreeColumn,
        collectionList: CollectionList,
        featuredPosts: FeaturedBlog,
        pageContent: PageContent,
        gallery: Gallery,
        pricing: Pricing,
        fileUpload: File,
        testimonial: Testimonial,
        accordion: Faq
    };
    return contentBlocks.map((block, index) => {
        if (Components[block._type]) {
            return React.createElement(Components[block._type], {
                key: block._key,
                block: block,
                type,
                raw: _rawContentBlocks[index],
            });
        } else {
            return null
        }
    })
}

export default ContentBlocks
