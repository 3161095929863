import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Post = ({ post }) => {
    const image = post.image
    return (
        <div className='p-0 card'>
            <Link className='group' to={`/blog/${post.slug.current}`}>
                <div className='overflow-hidden'>
                    <GatsbyImage className='transform hover:scale-105 transition-transform duration-700' image={image.asset.gatsbyImageData} alt={image.alt ? image.alt : ""} />
                </div>
                <div className='p-5 text-center'>
                    <p className='text-sm'>{post.date}</p>
                    <h4 className='text-3xl text-text-dark group-hover:underline'>{post.title}</h4>
                </div>
            </Link>
        </div>
    )
}

export default Post
