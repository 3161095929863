import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import PageContent from './pageContent'

const Hero = ({ block }) => {
    const { heroImage, heroText, heroCTA1, heroCTA2, heroLink, heroLink2 } = block
    const linkFinder = (props) => {
        let link
        switch (props._type) {
            case 'collection':
                link = `/collections/${props.slug.current}`;
                break;
            case 'post':
                link = `/posts/${props.slug.current}`;
                break;
            case 'home':
                link = `/`;
                break;
            case 'product':
                link = `/products/${props.store.slug.current}`;
                break;
            default:
                link = `/${props.slug.current}`
        } return (
            link
        )
    }
    return (
        <div className='text-text-dark relative md:h-full mb-12'>
            <div>
                <div className='absolute w-full h-full z-10 grid items-center'>
                    <div className='hero mt-2 md:mt-0 text-center text-text-light'>
                        <PageContent block={heroText} />
                        {heroCTA1 &&
                            <div className='mt-8 sm-mt-0 sm:inline-block'>
                                <Link to={linkFinder(heroLink)} className='bg-bg-1 text-accent-1 px-5 py-3 uppercase'>{heroCTA1}</Link>
                            </div>
                        }
                        {heroCTA2 &&
                            <div className='mt-12 sm:mt-0 sm:inline-block sm:ml-8'>
                                <Link to={linkFinder(heroLink2)} className='button offset bg-bg-1 text-accent-1 px-5 py-3 uppercase'>{heroCTA2}</Link>
                            </div>
                        }
                    </div>
                </div>
                <div className='w-full h-[32rem] bg-accent-1'>
                    <GatsbyImage
                        loading='eager'
                        className='opacity-20 h-[32rem]'
                        image={heroImage.asset.gatsbyImageData}
                        alt={heroImage.alt ? heroImage.alt : ""}
                    />
                </div>
            </div>
        </div>
    )
}

export default Hero
